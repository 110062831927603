<script>
// import PricingTable from '@/components/pricing/PricingTable';
import feather from 'feather-icons';
// import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import TestimonialCard from './new/components/TestimonialCard.vue';

export default {
    name: 'About',
    components: {
        TestimonialCard
    },

    data: () => {
        return {
            testimonials: [
                {
                    id: 4,
                    title: 'Niranjan',
                    description: "<p>My son learnt to play chess on one of those big chess boards (with large movable pieces) that some parks/hotels have and we enjoyed playing chess often while we were parked/waiting in our car’s chess app. I wanted to trial lessons with Coach Ryan to see if Adi would be responsive to playing someone else besides his father. Adi was playing too much Minecraft and Pokemon and we were worried it might be turning his brain to mush! We figured that chess lessons might be the perfect thing to replace time spent playing video games with something more conducive to his brain development.</p><p>Ryan turned out to be the perfect fit. He connects with my son really well, they have a lot of fun during class time. Ryan gives Adi homework and holds him accountable for following through. Ryan asks engaging questions and helps stoke a fire to keep Adi’s interest in chess alive and well. Whenever he plays with his classmates he wins all his games and reports to me with a beaming grin on his face. My son absolutely benefits a great deal. After just a few weeks, we were sure this was a great fit. We’ve been taking lessons for 4 months and things are going strong. I’d highly recommend Coach Ryan to other families interested in chess lessons for their child.</p>",
                    image: require('@/assets/images/components/default-user-img.png')
                },
                {
                    id: 7,
                    title: 'Maadhav',
                    description: "<p>We noticed Maadhav liked chess when he tried it out during after school enrichment classes.</p><p>During the pandemic, when all the other activities came to a halt, I was looking for a chess coach to help provide some structure to our son’s routine, keep his interest in chess alive, not lose touch with listening to directions and also a person he can build a rapport with. After trying a different coach first, I am glad I found Ryan who was a great fit for our son and checked all the boxes we were looking for in a chess coach.</p><p>What I appreciate the most is his ability to bond with kids.</p><p>Ryan is also proactive about sharing his observations, feedback and chess lesson summary notes with parents. He’s good at listening to kids and giving them choices about the kind of activity they would like to do during class.</p><p>When Maadhav’s proud of a game he won on chesskid.com, he loves sending an email to Coach Ryan and joyfully analyzes it with Ryan during class. My son watches the clock to be sure he is not late for his chess class with Coach Ryan. We’re happy he’s has found a great coach and friend!</p>",
                    image: require('@/assets/images/components/default-user-img.png')
                },
                {
                    id: 6,
                    title: 'Abigail',
                    description: "<p>Our son has always loved chess, and during the pandemic we realized our child needed something stimulating to look forward to. Ryan’s approach gave Nolan that and so much more. Our child’s chess game improved while his enthusiasm, as well as his willingness to take risks and make mistakes, also developed. However, that’s not all that changed. We watched with delight as remote learning became engaging. Ryan has the unique ability to meet kids where they are at with curiosity, humor, skill, direction, patience, and he doesn’t miss a beat. Children lost many things during the pandemic including normalcy, structure, social interactions, and the chance to just be kids. With Ryan, our child got all these things back, while improving his chess game. To this day, I’ve never won a match against Nolan. And his dad, who plays multiple times a day, almost never does. I can’t recommend his services enough.</p>",
                    image: require('@/assets/images/components/default-user-img-girl.png')
                },
                {
                    id: 2,
                    title: 'Rushaan',
                    description: "<p>Coach Ryan was my first chess coach; he taught me several critical skills that benefited me on and off the chessboard. His composure, compassion, and confidence supported me through my chess learnings.</p> <p>Currently, I am in the top-15 in the USA for my age, and without Coach Ryan, I would not have developed a strong chess foundation for my chess endeavors. If you are a student that likes fun, strategy, focus, and consistency while learning chess Coach Ryan is the perfect fit. Thank you, Coach Ryan, for all your support.</p>",
                    image: require('@/assets/images/components/default-user-img.png')
                },
                {
                    id: 5,
                    title: 'Mother, 10/23/24',
                    description: "<p>Our son, E, began taking chess lessons with Ryan at age 10. From the start, Ryan created a warm, inclusive, and fun environment that helped E feel comfortable and engaged during the online sessions. Over the years, Ryan has demonstrated a keen understanding of E's skill level, consistently tracking his progress and introducing new challenges to prevent stagnation. Beyond the technical aspects, Ryan’s encouragement and support have been invaluable in boosting E's confidence. We also greatly appreciate Ryan’s flexibility with scheduling, which has made it easy to keep up with lessons.</p>",
                    image: require('@/assets/images/components/default-user-img-girl.png')
                },
                {
                    id: 3,
                    title: 'Leon',
                    description: "<p>I had the privilege of working with Ryan as my chess coach and I am extremely impressed with his skills and teaching methods. Just two weeks ago, I lost all three of my games and felt discouraged in my chess abilities. But under Ryan’s guidance and support, I was able to turn it around and win a quad just two weeks later. Ryan’s exceptional understanding of the game, combined with his patient and supportive coaching style, made a huge impact on my growth and success as a chess player. I highly recommend Ryan to anyone looking to improve their game and reach new levels of success in chess.</p>",
                    image: require('@/assets/images/components/default-user-img.png')
                },
                {
                    id: 1,
                    title: 'Yiyang',
                    description: "<p>We found out Yiyang loves chess during his second grade enrichment chess class and we’ve been fortunate to find Ryan to help him. Yiyang has attended private lessons with Ryan since Dec, 2019. At that time, his rating was just 800. After 5+ months, his rating has increased to 1100+. More importantly, we’re glad to see Yiyang has become so confident and calm during the chess tournaments.</p> <p>Ryan provided all the needed guidance to Yiyang, including books to read, tournaments to attend and games to play. He closely tracks Yiyang’s progress and adjusts the learning plan accordingly. He also tries all the ways to encourage Yiyang to practice and keep making progress. During one of the tournaments, Yiyang won 5 of 5 rounds and got 1st place in second grade.</p> <p> After the tournament, the first thing Yiyang thought of is to share this good news with Ryan. I think this is the best proof that Ryan is not only the teacher for him but also his best friend. Really appreciate the help and support Ryan gives to Yiyang.</p>",
                    image: require('@/assets/images/components/TestimonialUser1.png')
                },
            ],
        };
    },
    mounted() {
        feather.replace();
    },
    updated() {
        feather.replace();
    },
    methods: {},
};
</script>

<template>
    <div class="w-full mx-auto container mt-8 mb-2">

        <h1 class="page-header-xl tracking-wide text-dark-800 dark:text-white text-center my-2">
            Testimonials
        </h1>
    </div>
    <!-- <div class="w-full mx-auto container mt-8 flex justify-center">
        <img class="w-auto" src="@/assets/images/components/TestimonialBanner1.png">
    </div>
    <div class="w-full mx-auto container -mt-10 mb-20 flex justify-center">
        <img class="w-16 lg:w-20 rounded-full" src="@/assets/images/components/TestimonialUser1.png">
    </div> -->
    <div class="container mx-auto px-4 py-8">
        <ul class="w-full divide-y divide-gray-200 dark:divide-gray-700">
            <li class="pb-3 sm:pb-4" v-for="(item, index) in testimonials" :key="index">
                <TestimonialCard :title="item.title" :description="item.description" :imageSrc="item.image" />
            </li>
        </ul>

        <!-- <ul class="list-none grid grid-cols-1 sm:grid-cols-1 gap-4">
            <li v-for="(item, index) in testimonials" :key="index" class="flex">
                <div class="flex-1">
                    <h2 class="text-lg font-bold mb-2">{{ item.title }}</h2>
                    <p v-if="item.description.length <= 100">{{ item.description }}</p>
                    <p v-else class="line-clamp-3">{{ item.description }}</p>
                    <button class="text-blue-500 hover:underline" v-if="item.description.length > 100">Read
                        More</button>
                </div>
                <div class="flex-1">
                    <img :src="item.image" alt="Image" class="w-full h-auto object-cover">
                </div>
            </li>
        </ul> -->
    </div>
    <!-- <div class="lg:max-w-3xl container px-2 md:px-6 my-10 testimonials-slide mx-auto">
        <div class="text-left rounded-2xl p-4 md:p-6 border-2 border-black">
            <Splide :options="{ rewind: true, autoHeight: true }" aria-label="Vue Splide Example">
                <SplideSlide v-for="item in testimonials" :key="item.id">
                    <div class="flex items-center">
                        <img class="w-20 rounded-full" :src="item.image">
                        <h2 class="text-3xl ml-2">{{ item.title }}</h2>
                    </div>
                    <h2 class="text-3xl text-center">{{ item.title }}</h2>
                    <p class="leading-8 text-lg mb-8 ls-rct text-base-rct" v-html="item.description" />
                </SplideSlide>
            </Splide>
        </div>
    </div> -->
    <div class="text-gray-900 pt-12 pr-0 pb-14 pl-0 bg-white" v-if="0">
        <div class="w-full pt-4 pr-5 pb-6 pl-5 mt-0 mr-auto mb-0 ml-auto space-y-5 sm:py-8 md:py-12 sm:space-y-8 md:space-y-16
        max-w-7xl">
            <div class="flex flex-col items-center sm:px-5 md:flex-row">
                <div
                    class="flex flex-col items-start justify-center w-full h-full pt-6 pr-0 pb-6 pl-0 mb-6 md:mb-0 md:w-1/2">
                    <div class="flex flex-col items-start justify-center h-full space-y-3 transform md:pr-10 lg:pr-16
              md:space-y-5">
                        <div class="bg-green-500 flex items-center leading-none rounded-full text-gray-50 pt-1.5 pr-3 pb-1.5 pl-2
                uppercase inline-block">
                            <p class="inline">
                                <svg class="w-3.5 h-3.5 mr-1" fill="currentColor" viewbox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0
                    00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755
                    1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1
                    0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                            </p>
                            <p class="inline text-xs font-medium">New</p>
                        </div>
                        <a class="text-4xl font-bold leading-none lg:text-5xl xl:text-6xl">Write anything. Be
                            creative.</a>
                        <div class="pt-2 pr-0 pb-0 pl-0">
                            <p class="text-sm font-medium inline">author:</p>
                            <a class="inline text-sm font-medium mt-0 mr-1 mb-0 ml-1 underline">Jack Sparrow</a>
                            <p class="inline text-sm font-medium mt-0 mr-1 mb-0 ml-1">· 23rd, April 2021 ·</p>
                            <p class="text-gray-200 text-sm font-medium inline mt-0 mr-1 mb-0 ml-1">1hr 20min. read</p>
                        </div>
                    </div>
                </div>
                <div class="w-full md:w-1/2">
                    <div class="block">
                        <img src="https://images.unsplash.com/photo-1626314928277-1d373ddb6428?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mzd8fHxlbnwwfHx8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60"
                            class="object-cover rounded-lg max-h-64 sm:max-h-96 btn- w-full h-full" />
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-12 sm:px-5 gap-x-8 gap-y-16">
                <div class="flex flex-col items-start col-span-12 space-y-3 sm:col-span-6 xl:col-span-4">
                    <img src="https://images.unsplash.com/photo-1626318305863-bb23d0297c0b?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60"
                        class="object-cover w-full mb-2 overflow-hidden rounded-lg shadow-sm max-h-56 btn-" />
                    <p class="bg-green-500 flex items-center leading-none text-sm font-medium text-gray-50 pt-1.5 pr-3 pb-1.5 pl-3
              rounded-full uppercase inline-block">Entertainment</p>
                    <a class="text-lg font-bold sm:text-xl md:text-2xl">Improving your day to the MAX</a>
                    <p class="text-sm text-black">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                        eiusmod
                        tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
                    <div class="pt-2 pr-0 pb-0 pl-0">
                        <a class="inline text-xs font-medium mt-0 mr-1 mb-0 ml-0 underline">Jack Sparrow</a>
                        <p class="inline text-xs font-medium mt-0 mr-1 mb-0 ml-1">· 23rd, March 2021 ·</p>
                        <p class="inline text-xs font-medium text-gray-300 mt-0 mr-1 mb-0 ml-1">1hr 20min. read</p>
                    </div>
                </div>
                <div class="flex flex-col items-start col-span-12 space-y-3 sm:col-span-6 xl:col-span-4">
                    <img src="https://images.unsplash.com/photo-1626285861696-9f0bf5a49c6d?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTl8fHxlbnwwfHx8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60"
                        class="object-cover w-full mb-2 overflow-hidden rounded-lg shadow-sm max-h-56 btn-" />
                    <p class="bg-green-500 flex items-center leading-none text-sm font-medium text-gray-50 pt-1.5 pr-3 pb-1.5 pl-3
              rounded-full uppercase inline-block">Entertainment</p>
                    <a class="text-lg font-bold sm:text-xl md:text-2xl">Improving your day to the MAX</a>
                    <p class="text-sm text-black">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                        eiusmod
                        tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
                    <div class="pt-2 pr-0 pb-0 pl-0">
                        <a class="inline text-xs font-medium mt-0 mr-1 mb-0 ml-0 underline">Jack Sparrow</a>
                        <p class="inline text-xs font-medium mt-0 mr-1 mb-0 ml-1">· 23rd, March 2021 ·</p>
                        <p class="inline text-xs font-medium text-gray-300 mt-0 mr-1 mb-0 ml-1">1hr 20min. read</p>
                    </div>
                </div>
                <div class="flex flex-col items-start col-span-12 space-y-3 sm:col-span-6 xl:col-span-4">
                    <img src="https://images.unsplash.com/photo-1626197031507-c17099753214?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MzR8fHxlbnwwfHx8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60"
                        class="object-cover w-full mb-2 overflow-hidden rounded-lg shadow-sm max-h-56 btn-" />
                    <p class="bg-green-500 flex items-center leading-none text-sm font-medium text-gray-50 pt-1.5 pr-3 pb-1.5 pl-3
              rounded-full uppercase inline-block">Entertainment</p>
                    <a class="text-lg font-bold sm:text-xl md:text-2xl">Improving your day to the MAX</a>
                    <p class="text-sm text-black">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                        eiusmod
                        tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
                    <div class="pt-2 pr-0 pb-0 pl-0">
                        <a class="inline text-xs font-medium mt-0 mr-1 mb-0 ml-0 underline">Jack Sparrow</a>
                        <p class="inline text-xs font-medium mt-0 mr-1 mb-0 ml-1">· 23rd, March 2021 ·</p>
                        <p class="inline text-xs font-medium text-gray-300 mt-0 mr-1 mb-0 ml-1">1hr 20min. read</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>