<template>
    <div class="flex p-6 rounded-lg shadow-lg max-w-4xl mx-auto">
        <!-- Image Section -->
        <div class="flex-shrink-0 hidden sm:block">
            <img :src="imageSrc" alt="Image" class="rounded-lg shadow-md object-cover h-24 w-24" />
        </div>

        <!-- Content Section -->
        <div class="ml-0 sm:ml-6 flex-1">
            <!-- Title -->
            <h2 class="text-2xl font-bold text-black mb-2 text-left">{{ title }}</h2>

            <!-- Description (with scroll for overflow) -->
            <!-- <p class="text-gray-700 text-sm leading-relaxed max-h-32 overflow-auto scrollbar-thin scrollbar-thumb-gray-600 pr-2"
                v-html="description" /> -->

            <p class="text-gray-800 text-sm leading-relaxed text-left">
                <span v-html="isExpanded ? description : truncatedDescription"></span>
                <span v-if="showReadMore" @click="toggleReadMore" class="text-green-500 cursor-pointer">
                    {{ isExpanded ? 'Read Less' : 'Read More' }}
                </span>
            </p>

            <!-- Action Button -->
            <!-- <button class="mt-4 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition">
                Listen Now
            </button> -->
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        description: String,
        imageSrc: String,
    },
    data() {
        return {
            isExpanded: false,  // Initial state for 'Read More' functionality
        };
    },
    computed: {
        // Show truncated description (first 200 characters, keeping HTML tags intact)
        truncatedDescription() {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = this.description;
            const textContent = tempDiv.textContent || tempDiv.innerText || '';
            return textContent.length > 200
                ? textContent.substring(0, 200) + '...'
                : textContent;
        },
        // Show the "Read More" link if description exceeds 200 characters
        showReadMore() {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = this.description;
            const textContent = tempDiv.textContent || tempDiv.innerText || '';
            return textContent.length > 200;
        },
    },
    methods: {
        toggleReadMore() {
            console.log(this.isExpanded)
            this.isExpanded = !this.isExpanded;
        },
    },
};
</script>

<style scoped>
/* Optional styling for smooth scrollbar */
.scrollbar-thin::-webkit-scrollbar {
    width: 6px;
}

.scrollbar-thumb-gray-600::-webkit-scrollbar-thumb {
    background-color: rgba(107, 114, 128, 0.6);
}
</style>