<script>
export default {
	props: ['showModal', 'isOpen'],
	data() {
		return {
			links: [
				{
					link: '/',
					title: 'Home',
				},
				{
					link: '/lesson-plan',
					title: 'Lesson Plan',
				},
				{
					link: '/activities',
					title: 'Activities',
				},
				{
					link: '/about',
					title: 'About',
				},
				{
					link: '/book-class',
					title: 'Scheduling',
				},
			],
		};
	},
	methods: {
		hideMenu() {
			this.$parent.hideMenu();
		}
	}
};
</script>

<template>
	<!-- Header links -->
	<div :class="isOpen ? 'block' : 'hidden'"
		class="m-0 sm:ml-4 sm:flex p-5 sm:p-0 justify-center items-center nav-bar">
		<template v-for="item in links" :key="item.link">
			<router-link @click="hideMenu()" :to="item.link"
				class="font-inter block text-left text-3xl font-medium text-primary-dark dark:text-ternary-light hover:text-pink-600 dark:hover:text-pink-300  sm:mx-4 my-2 sm:py-2 "
				aria-label="Projects" v-if="item.title !== 'Scheduling'">{{ item.title }}</router-link>
			<router-link @click="hideMenu()" type="button"
				class="text-white rounded-tl-xl rounded-br-xl bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium text-xl xl:text-2xl px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 h-[72px]"
				v-if="item.title === 'Scheduling'" :to="item.link">
				{{ item.title }}
			</router-link>

		</template>
		<!-- <router-link @click="hideMenu()" to="/about"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-pink-600 dark:hover:text-pink-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="About Me">About</router-link>
		<router-link @click="hideMenu()" to="/lesson-plan"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-pink-600 dark:hover:text-pink-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="Lesson Plan">Lesson Plan</router-link>
		<router-link @click="hideMenu()" to="/pricing"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-pink-600 dark:hover:text-pink-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="Pricing">Pricing</router-link>
		<router-link @click="hideMenu()" to="/book-class"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-pink-600 dark:hover:text-pink-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="Contact">Availability</router-link>
		<router-link @click="hideMenu()" to="/testimonials"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-pink-600 dark:hover:text-pink-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="Testimonials">Testimonials</router-link> -->
		<!-- <router-link to="/contact"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-pink-600 dark:hover:text-pink-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="Contact">Contact</router-link> -->
		<div class="border-t-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-light dark:border-secondary-dark">
			<!-- <button
				class="font-general-medium sm:hidden block text-left text-md font-medium bg-pink-500 hover:bg-pink-600 text-white shadow-sm rounded-sm px-4 py-2 mt-2 duration-500"
				@click="showModal()"
				aria-label="Orçamento Botão"
			>
				orçamento
			</button> -->
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
