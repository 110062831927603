<template>
    <div class="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center p-4">
      <h1 class="text-9xl font-bold text-[#ffd60a]">404</h1>
      <h2 class="text-2xl font-semibold text-gray-900 mt-4">Oops! Page Not Found</h2>
      <p class="text-gray-600 mt-2">It seems like you've reached a page that doesn't exist.</p>
      <router-link to="/" class="mt-6">
        <button class="px-6 py-3 bg-[#ffd60a] text-[#1f2937] font-semibold rounded-md hover:bg-[#e6c507] transition">
          Go Back Home
        </button>
      </router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFoundPage',
  };
  </script>
  
  <style scoped>
  </style>
  