<script>
// import PricingTable from '@/components/pricing/PricingTable';
import feather from 'feather-icons';
// import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import ActivityCard from './new/components/ActivityCard.vue';
import { useRoute } from 'vue-router';

export default {
    name: 'About',
    components: {
        ActivityCard
    },

    data: () => {
        return {
            activities: [
                {
                    id: 4,
                    title: '1️⃣ Inspiring Master Game Analysis',
                    description: "<p>Let’s kick off with an analysis of a truly inspiring game played by a master. I break down strategic decisions, reveal powerful tactics, and provide relatable insights to spark their curiosity and passion for advanced chess concepts.</p>",
                    image: require('@/assets/images/components/X.png')
                },
                {
                    id: 7,
                    title: '2️⃣ Deep Dive Analysis of their Five Games',
                    description: "<p>We review five of their own games to pinpoint strengths and uncover growth areas. With personalized guidance, she’ll gain a deeper understanding of their unique play style and tactics, helping them reinforce what’s working while sharpening their strategic vision.</p>",
                    image: require('@/assets/images/components/X.png')
                },
                {
                    id: 6,
                    title: '3️⃣ High-Impact Live Help Game',
                    description: "<p>In this interactive experience, she plays a live game on chess.com, while I provide leading questions that subtly guide their moves. This approach has shown incredible results. A former student, Michael, prepared with eight live help games before his state championship, qualifying for state with a 3.5 out of 5 score. This exercise hones real-time decision-making, increasing confidence and skill.</p>",
                    image: require('@/assets/images/components/X.png')
                },
                {
                    id: 2,
                    title: '4️⃣ Odd Game Leaderboard – Blindfolded Fun',
                    description: "<p>For a light-hearted yet challenging activity, I play a blindfolded game, keeping it fun with semi-amusing commentary. She attempts to outplay me while I strike a careful balance between game focus and engaging them directly. Among my various activities, this is the most requested by students—perfect for sparking enjoyment in learning.</p>",
                    image: require('@/assets/images/components/X.png')
                },
                {
                    id: 5,
                    title: '5️⃣ Puzzle Rating Pursuit',
                    description: "<p>In this intense puzzle-solving session, I introduce a specific thinking model to focus on for the day. Over a 20-minute period, she applies this “lens” to every puzzle, enhancing their focus and efficiency. Watching their puzzle rating climb on chess.com, with real-time feedback, is a thrill and is often my personal favorite to teach. This training is surgical and rewarding, sharpening their ability to tackle key moments with precision.</p>",
                    image: require('@/assets/images/components/X.png')
                },
            ],
        };
    },
    mounted() {
        feather.replace();
    },
    updated() {
        feather.replace();
    },
    setup() {
        const route = useRoute();

        console.log('Current path:', route.path);
        console.log('Route params:', route.params);
        console.log('Route query:', route.query);

        return { route };
    },
    methods: {},
};
</script>

<template>
    <div class="w-full mx-auto container mt-8 mb-2">

        <h1 class="page-header-xl tracking-wide text-dark-800 dark:text-white text-center my-2">
            Activities
        </h1>
        <div class="max-w-4xl mx-auto">
            <strong class="text-lg">Would you like to motivate your child to try out lessons with Coach Ryan? Show them this page and encourage them to analyze this refined list of tailored activities below to pique their interest. 
            </strong>
        </div>
    </div>
    <!-- <div class="w-full mx-auto container mt-8 flex justify-center">
        <img class="w-auto" src="@/assets/images/components/TestimonialBanner1.png">
    </div>
    <div class="w-full mx-auto container -mt-10 mb-20 flex justify-center">
        <img class="w-16 lg:w-20 rounded-full" src="@/assets/images/components/TestimonialUser1.png">
    </div> -->
    <div class="container mx-auto px-4 py-8">
        <ul class="w-full divide-y divide-gray-200 dark:divide-gray-700">
            <li class="pb-3 sm:pb-4" v-for="(item, index) in activities" :key="index">
                <ActivityCard :title="item.title" :description="item.description" :imageSrc="$route.path === '/activities-2' ? item.image : ''" />
            </li>
        </ul>

        <!-- <ul class="list-none grid grid-cols-1 sm:grid-cols-1 gap-4">
            <li v-for="(item, index) in activities" :key="index" class="flex">
                <div class="flex-1">
                    <h2 class="text-lg font-bold mb-2">{{ item.title }}</h2>
                    <p v-if="item.description.length <= 100">{{ item.description }}</p>
                    <p v-else class="line-clamp-3">{{ item.description }}</p>
                    <button class="text-blue-500 hover:underline" v-if="item.description.length > 100">Read
                        More</button>
                </div>
                <div class="flex-1">
                    <img :src="item.image" alt="Image" class="w-full h-auto object-cover">
                </div>
            </li>
        </ul> -->
    </div>
    <!-- <div class="lg:max-w-3xl container px-2 md:px-6 my-10 activities-slide mx-auto">
        <div class="text-left rounded-2xl p-4 md:p-6 border-2 border-black">
            <Splide :options="{ rewind: true, autoHeight: true }" aria-label="Vue Splide Example">
                <SplideSlide v-for="item in activities" :key="item.id">
                    <div class="flex items-center">
                        <img class="w-20 rounded-full" :src="item.image">
                        <h2 class="text-3xl ml-2">{{ item.title }}</h2>
                    </div>
                    <h2 class="text-3xl text-center">{{ item.title }}</h2>
                    <p class="leading-8 text-lg mb-8 ls-rct text-base-rct" v-html="item.description" />
                </SplideSlide>
            </Splide>
        </div>
    </div> -->
    <div class="text-gray-900 pt-12 pr-0 pb-14 pl-0 bg-white" v-if="0">
        <div class="w-full pt-4 pr-5 pb-6 pl-5 mt-0 mr-auto mb-0 ml-auto space-y-5 sm:py-8 md:py-12 sm:space-y-8 md:space-y-16
        max-w-7xl">
            <div class="flex flex-col items-center sm:px-5 md:flex-row">
                <div
                    class="flex flex-col items-start justify-center w-full h-full pt-6 pr-0 pb-6 pl-0 mb-6 md:mb-0 md:w-1/2">
                    <div class="flex flex-col items-start justify-center h-full space-y-3 transform md:pr-10 lg:pr-16
              md:space-y-5">
                        <div class="bg-green-500 flex items-center leading-none rounded-full text-gray-50 pt-1.5 pr-3 pb-1.5 pl-2
                uppercase inline-block">
                            <p class="inline">
                                <svg class="w-3.5 h-3.5 mr-1" fill="currentColor" viewbox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0
                    00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755
                    1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1
                    0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                            </p>
                            <p class="inline text-xs font-medium">New</p>
                        </div>
                        <a class="text-4xl font-bold leading-none lg:text-5xl xl:text-6xl">Write anything. Be
                            creative.</a>
                        <div class="pt-2 pr-0 pb-0 pl-0">
                            <p class="text-sm font-medium inline">author:</p>
                            <a class="inline text-sm font-medium mt-0 mr-1 mb-0 ml-1 underline">Jack Sparrow</a>
                            <p class="inline text-sm font-medium mt-0 mr-1 mb-0 ml-1">· 23rd, April 2021 ·</p>
                            <p class="text-gray-200 text-sm font-medium inline mt-0 mr-1 mb-0 ml-1">1hr 20min. read</p>
                        </div>
                    </div>
                </div>
                <div class="w-full md:w-1/2">
                    <div class="block">
                        <img src="https://images.unsplash.com/photo-1626314928277-1d373ddb6428?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mzd8fHxlbnwwfHx8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60"
                            class="object-cover rounded-lg max-h-64 sm:max-h-96 btn- w-full h-full" />
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-12 sm:px-5 gap-x-8 gap-y-16">
                <div class="flex flex-col items-start col-span-12 space-y-3 sm:col-span-6 xl:col-span-4">
                    <img src="https://images.unsplash.com/photo-1626318305863-bb23d0297c0b?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60"
                        class="object-cover w-full mb-2 overflow-hidden rounded-lg shadow-sm max-h-56 btn-" />
                    <p class="bg-green-500 flex items-center leading-none text-sm font-medium text-gray-50 pt-1.5 pr-3 pb-1.5 pl-3
              rounded-full uppercase inline-block">Entertainment</p>
                    <a class="text-lg font-bold sm:text-xl md:text-2xl">Improving your day to the MAX</a>
                    <p class="text-sm text-black">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                        eiusmod
                        tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
                    <div class="pt-2 pr-0 pb-0 pl-0">
                        <a class="inline text-xs font-medium mt-0 mr-1 mb-0 ml-0 underline">Jack Sparrow</a>
                        <p class="inline text-xs font-medium mt-0 mr-1 mb-0 ml-1">· 23rd, March 2021 ·</p>
                        <p class="inline text-xs font-medium text-gray-300 mt-0 mr-1 mb-0 ml-1">1hr 20min. read</p>
                    </div>
                </div>
                <div class="flex flex-col items-start col-span-12 space-y-3 sm:col-span-6 xl:col-span-4">
                    <img src="https://images.unsplash.com/photo-1626285861696-9f0bf5a49c6d?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTl8fHxlbnwwfHx8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60"
                        class="object-cover w-full mb-2 overflow-hidden rounded-lg shadow-sm max-h-56 btn-" />
                    <p class="bg-green-500 flex items-center leading-none text-sm font-medium text-gray-50 pt-1.5 pr-3 pb-1.5 pl-3
              rounded-full uppercase inline-block">Entertainment</p>
                    <a class="text-lg font-bold sm:text-xl md:text-2xl">Improving your day to the MAX</a>
                    <p class="text-sm text-black">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                        eiusmod
                        tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
                    <div class="pt-2 pr-0 pb-0 pl-0">
                        <a class="inline text-xs font-medium mt-0 mr-1 mb-0 ml-0 underline">Jack Sparrow</a>
                        <p class="inline text-xs font-medium mt-0 mr-1 mb-0 ml-1">· 23rd, March 2021 ·</p>
                        <p class="inline text-xs font-medium text-gray-300 mt-0 mr-1 mb-0 ml-1">1hr 20min. read</p>
                    </div>
                </div>
                <div class="flex flex-col items-start col-span-12 space-y-3 sm:col-span-6 xl:col-span-4">
                    <img src="https://images.unsplash.com/photo-1626197031507-c17099753214?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MzR8fHxlbnwwfHx8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60"
                        class="object-cover w-full mb-2 overflow-hidden rounded-lg shadow-sm max-h-56 btn-" />
                    <p class="bg-green-500 flex items-center leading-none text-sm font-medium text-gray-50 pt-1.5 pr-3 pb-1.5 pl-3
              rounded-full uppercase inline-block">Entertainment</p>
                    <a class="text-lg font-bold sm:text-xl md:text-2xl">Improving your day to the MAX</a>
                    <p class="text-sm text-black">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                        eiusmod
                        tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
                    <div class="pt-2 pr-0 pb-0 pl-0">
                        <a class="inline text-xs font-medium mt-0 mr-1 mb-0 ml-0 underline">Jack Sparrow</a>
                        <p class="inline text-xs font-medium mt-0 mr-1 mb-0 ml-1">· 23rd, March 2021 ·</p>
                        <p class="inline text-xs font-medium text-gray-300 mt-0 mr-1 mb-0 ml-1">1hr 20min. read</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>