<script>
import PopupContent from '@/views/new/components/PopupContent';
import AppBanner from '@/components/shared/AppBanner';
// import Slider from '@/components/reusable/Slider';
import ProjectsGrid from '../components/projects/ProjectsGrid.vue';
// import Button from '../components/reusable/Button.vue';

export default {
	name: 'Home',
	components: {
		AppBanner,
		// Slider,
		ProjectsGrid,
		// Button,
		PopupContent,
	},
	data() {
		return {
			data: [
				{
					id: 1,
					img: '@/assets/images/Chess-Play-Banner.jpg',
				},
				{
					id: 2,
					img: '@/assets/images/Chess-Play-Banner.jpg',
				},
				{
					id: 3,
					img: '@/assets/images/Chess-Play-Banner.jpg',
				},
				{
					id: 4,
					img: '@/assets/images/Chess-Play-Banner.jpg',
				},
			],
			prices: [
				{
					title: "In-Person Deals will offered next week, while supply lasts. Deals will include offers exclusively on first-come first-serve basis."
				},
				{
					price: 200,
					hours: 1,
					title: "1 for $200",
					points: [
						'This is the worst option for sure',
					],
					valid: 'Already passed'
				},
				{
					price: 250,
					hours: 5.5,
					title: "5.5 hours for $250",
					points: [
						'5.5 hours of lessons',
						'That\'s just $45/hour'
					],
					valid: '3pm PST'
				},
				{
					price: 1500,
					hours: 15,
					title: "15 hours for $500",
					points: [
						'15 hours of lessons',
						'50% off'
					],
					valid: '3pm PST'
				}
			],
			modalOpen: true,
			info: { email: 'chesswithryan@gmail.com', paypalEmail: 'ryansackerman@gmail.com', phone: '425-623-7900' }
		};
	},
	mounted() {
		this.closeModal()
	},
	methods: {

		openModal() {
			this.modalOpen = true;
		},
		closeModal() {
			this.modalOpen = false;
		},
		preventClose(event) {
			event.stopPropagation(); // Prevent click event from reaching the backdrop
		}
	}
};
</script>

<template>
	<div class="container mx-auto">
		<!-- <Slider :data="data" /> -->
		<!-- Banner -->
		<AppBanner class="mb-5 sm:mb-8" />

		<!-- Projects -->
		<ProjectsGrid />
		<!-- Load mais projetos button OCULTO -->
		<!-- <div class="mt-10 sm:mt-20 flex justify-center">
			<router-link
				to="/projects"
				class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-pink-500 hover:bg-pink-600 focus:ring-1 focus:ring-pink-900 text-white text-lg sm:text-xl duration-300"
				aria-label="mais projetos"
			>
				<Button title="mais projetos" />
			</router-link>
		</div> -->

		<PopupContent :modalOpen="modalOpen" :email="info.email" :paypalEmail="info.paypalEmail" :prices="prices"
			@close-modal="closeModal" />

	</div>
</template>

<style scoped></style>
