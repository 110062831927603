<script>
import Button from '../../../components/reusable/Button.vue';
export default {

    components: {
        Button,
    },
    data() {
        return {
            offerEndsAt: new Date('2024-06-15')
        }
    },
    props: {
        modalOpen: Boolean,
        email: String,
        paypalEmail: String,
        prices: Array
    },
    methods: {
        closeModal() {
            this.$emit('close-modal');
        },
        preventClose(event) {
            event.stopPropagation();
        },
        weeksLeft(endDate) {
            // Get the current date
            const currentDate = new Date();

            // Calculate the difference in milliseconds
            const difference = endDate - currentDate;

            // Convert milliseconds to weeks
            const weeks = Math.floor(difference / (1000 * 60 * 60 * 24 * 7));

            // Convert milliseconds to days
            const days = Math.floor((difference % (1000 * 60 * 60 * 24 * 7)) / (1000 * 60 * 60 * 24));

            // If less than a week left, return the remaining days
            if (weeks === 0) {
                return days + " days";
            }

            return weeks + " weeks";
        }
    }
}
</script>

<template>
    <div v-if="modalOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[1000]"
        @click="closeModal">
        <div class="bg-white p-4 w-full max-h-screen overflow-auto" @click="preventClose">
            <button @click="closeModal"
                class=" top-0 right-0 w-8 text-3xl p-2 bg-gray-600 hover:bg-gray-800 text-white float-right">&times;</button>
            <div class="md:p-12">


                <h1 class="mb-4"><b>State Discount Deals!!</b></h1>
                <label class="text-2xl font-inter">For BOTH <b class="text-3xl font-bold">online</b> AND <b
                        class="text-3xl font-bold">in-person</b> classes!</label>

                <!-- <div class="my-6 tracking-wide font-inter">

						<label class="text-lg">Subscribe to avoid missing out:</label>
						<strong class="text-xl"><a :href="'mailto:' + info.email">{{ info.email }}</a></strong>

					</div> -->


                <p class="uppercase tracking-wide text-sm text-amber-500 font-bold">Only {{ weeksLeft(offerEndsAt) }}
                    Left
                    to Book!</p>

                <div
                    class="mx-auto bg-white rounded-xl shadow-md overflow-hidden md:flex md:max-w-4xl lg:max-w-6xl mb-6">
                    <div class="w-full p-4 lg:grid grid-cols-2">
                        <div class="mx-auto">
                            <div class="mt-4">
                                <p class="text-2xl text-gray-500">About the Lessons:</p>
                                <ol class="list-disc list-inside text-left">
                                    <li>Private lessons</li>
                                    <li>Tailored to Your Needs</li>
                                    <li>One-on-One Attention</li>
                                    <li>Flexible Scheduling</li>
                                </ol>
                            </div>
                        </div>
                        <div class="mx-auto">
                            <div class="mt-4">
                                <p class="text-gray-500">How to Book:</p>
                                <ol class="list-disc list-inside text-left">
                                    <li>
                                        Choose Your Preferred Time Slot
                                        <router-link to="/book-class"
                                            class="font-general-medium text-indigo-500 text-left text-lg text-primary-dark dark:text-ternary-light hover:text-pink-600 dark:hover:text-pink-300 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
                                            aria-label="Book a Class">
                                            Schedule a class
                                        </router-link>
                                    </li>
                                    <li>Email to Book: <a :href="'mailto:' + email" class="text-indigo-500">{{ email
                                            }}</a></li>
                                    <li>Payment:
                                        <span class="">
                                            <span class="text-blue-700 font-semibold text-xl">P</span>
                                            <span class="text-blue-700 font-semibold text-xl">a</span>
                                            <span class="text-blue-700 font-semibold text-xl">y</span>
                                            <span class="text-blue-700 font-semibold text-xl">P</span>
                                            <span class="text-blue-700 font-semibold text-xl">a</span>
                                            <span class="text-blue-700 font-semibold text-xl">l</span>
                                        </span> to <a class="font-semibold text-indigo-500">{{ paypalEmail }}</a>
                                    </li>
                                    <li>
                                        Payment must be made by end of the day
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="sm:grid grid-cols-2 sm:gap-4 xl:gap-8 lg:space-y-0">
                    <div v-for="(item, index) in prices" :key="index"
                        class="container  text-white p-8 rounded-lg shadow-lg max-w-md mx-auto mb-2 md:mb-0"
                        :class="{ 'bg-gradient-to-r from-amber-100 to-amber-300': index == 0, 'bg-gradient-to-r from-indigo-100 to-indigo-300': index == 1, 'bg-gradient-to-r from-teal-50 to-teal-200': index == 2, 'bg-gradient-to-r from-fuchsia-50 to-fuchsia-200': index == 3 }">
                        <div v-if="index > 0">
                            <h2 class="text-3xl font-bold mb-4">{{ item.title }}</h2>
                            <div class="text-lg">Get <b class="text-yellow-400 font-bold">discounts on</b> your next
                                purchase!</div>
                            <!-- <div class="text-base mb-4">Use coupon code:</div> -->
                            <!-- <div class="bg-white text-gray-800 rounded-lg px-4 py-2 flex items-center justify-between">
								<p class="text-2xl font-semibold">TAILOFFER25</p>
								<button class="bg-blue-800 text-white px-3 py-1 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">Copy</button>
							</div> -->
                            <ul role="list" class="mb-2 space-y-4 text-left">
                                <li class="flex items-center space-x-6" v-for="(point, index) in item.points"
                                    :key="index">
                                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                        fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                    <p>{{ point }}</p>
                                </li>
                            </ul>
                            <div class="text-sm mt-2">
                                <p>{{ index > 1 ? 'Valid until ' : 'Validity' }}
                                    <b class="font-semibold">
                                        {{ item.valid }}
                                    </b>
                                </p>
                                <router-link to="/terms">Terms and conditions apply.</router-link>
                            </div>
                        </div>
                        <div v-else>
                            <b class="text-2xl">{{ item.title }}</b>
                        </div>
                    </div>
                </div>

                <!-- <div class="space-y-8 lg:grid grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">

					<div v-for="(item, index) in prices" :key="item.price"
						class="flex flex-col w-full p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
						:class="{ 'bg-yellow-100': index === 0, 'bg-indigo-100': index === 1, 'bg-green-100': index === 2 }">
						<h2 class="mb-4 text-2xl font-semibold">{{ item.title }}</h2>
						
						<ul role="list" class="mb-8 space-y-4 text-left">
							<li class="flex items-center space-x-6" v-for="(point, index) in item.points"
								:key="index">
								<svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
									fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd"
										d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
										clip-rule="evenodd"></path>
								</svg>
								<p>{{ point }}</p>
							</li>
						</ul>
					</div>
				</div> -->
                <div class="md:flex justify-center my-6">
                    <router-link to="/book-class"
                        class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-pink-600 dark:hover:text-pink-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
                        aria-label="Book a Class">
                        <Button title="Schedule a class" color="primary" size="block" />
                    </router-link>
                    <a
                        class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-pink-600 dark:hover:text-pink-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark">
                        <button @click="closeModal" type="button"
                            class="bg-gray-200 text-gray-900 w-full font-bold h-[44px] bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-xl px-5 py-0 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">&times;
                            CLOSE</button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>