<script>
export default {
	props: ['title', 'color', 'size'],
	data: () => {
		return {
			class: 'bg-primary text-dark'
		};
	},
	mounted() {
		console.log(this.color)
		console.log(this.size)
	}
};
</script>

<template>
	<button v-if="color === 'primary'"
		:class="{ 'w-full': size === 'block', 'primary-btn font-anton font-bold bg-primary hover:text-teal-950 text-dark font-bold py-2 px-20 rounded shadow': color === 'primary', 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-8 rounded shadow': color !== 'primary' }">{{
			title }}</button>
	<!-- <img class="w-auto" src="@/assets/images/components/Button-Book-Class.png" /> -->
</template>

<style lang="scss" scoped></style>
