import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/app.css";
import BackToTop from "vue-backtotop";
import VueGtag from "vue-gtag";

const feather = require("feather-icons");
feather.replace();
console.log = () => {};
createApp(App)
  .use(router)
  .use(BackToTop)
  .use(VueGtag, { config: { id: "G-8QVM43H38C" } })
  .mount("#app");

const appTheme = localStorage.getItem("theme");
console.log = () => {};

// Check what is the active theme and change theme when user clicks on the theme button in header.
if (
  appTheme === "dark" &&
  document.querySelector("body").classList.contains("app-theme")
) {
  document.querySelector("body").classList.add("bg-primary-dark");
} else {
  document.querySelector("body").classList.add("bg-secondary-light");
}
