import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import NotFoundPage from "../views/404.vue"; // Import the 404 page component
import ActivitiesPage from "../views/Activities.vue"; // Import the 404 page component

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Ryan - Chess with Ryan",
    },
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/new/About.vue"),
    meta: {
      title: "Ryan - About",
    },
  },
  {
    path: "/projects",
    name: "Projects",
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/Projects.vue"),
    meta: {
      title: "Ryan - Projects",
    },
  },
  {
    path: "/projects/single-project",
    name: "Single Project",
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/SingleProject.vue"),
    meta: {
      title: "Ryan - Single Project",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
    meta: {
      title: "Ryan - Contact",
    },
  },
  {
    path: "/book-class",
    name: "Scheduling",
    component: () =>
      import(/* webpackChunkName: "scheduling" */ "../views/new/PricingV2.vue"),
    meta: {
      title: "Ryan - Book a Class",
    },
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () =>
      import(/* webpackChunkName: "pricing" */ "../views/Pricing.vue"),
    meta: {
      title: "Ryan - Pricing for Lessons",
    },
  },
  {
    path: "/lesson-plan",
    name: "LessonPlan",
    component: () =>
      import(/* webpackChunkName: "lesson-plan" */ "../views/LessonPlan.vue"),
    meta: {
      title: "Ryan - Lesson Plan",
    },
  },
  {
    path: "/:catchAll(.*)", // Catch-all route for undefined paths
    name: "NotFound",
    component: NotFoundPage,
    meta: {
      title: "Page Not Found",
    },
  },
  {
    path: "/activities", // Catch-all route for undefined paths
    name: "ActivitiesPage",
    component: ActivitiesPage,
    meta: {
      title: "Ryan's activities",
    },
  },
  {
    path: "/activities-2", // Catch-all route for undefined paths
    name: "ActivitiesPage2",
    component: ActivitiesPage,
    meta: {
      title: "Ryan's activities",
    },
  },
  {
    path: "/research-wip",
    name: "ResearchWip",
    component: () =>
      import(
        /* webpackChunkName: "lesson-plan" */ "../views/research/ResearchWip.vue"
      ),
    meta: {
      title: "Ryan - Research wip",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Set page title dynamically
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Chess with Ryan";
  next();
});

export default router;
